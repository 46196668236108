import socketIo from 'socket.io-client';

let socket: any = null;

function connectSocket() {
  socket = socketIo(process.env.REACT_APP_SOCKET_URL as string, {
    transports: ['websocket'],
    withCredentials: true
  });
}

const chatEvents = {
  CONNECTED_USER_SOCKET_ID: 'CONNECTED_USER_SOCKET_ID',
  CONNECTED_USER_DATA: 'CONNECTED_USER_DATA',
  ONLINE_USER: 'ONLINE_USER',
  GET_ROOMS: 'GET_ROOMS',
  IS_TYPING:'IS_TYPING',
  CREATE_ROOM: 'CREATE_ROOM',
  UPDATE_ROOM: 'UPDATE_ROOM',
  DELETE_ROOM: 'DELETE_ROOM',
  GET_MESSAGES: 'GET_MESSAGES',
  SEND_MESSAGE: 'SEND_MESSAGE',
  RECEIVE_NEW_ROOM: 'RECEIVE_NEW_ROOM',
  RECEIVE_NEW_MESSAGE: 'RECEIVE_NEW_MESSAGE',
  RECEIVE_UPDATE_MESSAGE: 'RECEIVE_UPDATE_MESSAGE',
  RECEIVE_DELETE_MESSAGE: 'RECEIVE_DELETE_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  SEND_FILE: 'SEND_FILE',
  READ_MESSAGES: 'READ_MESSAGES',
  RECEIVE_ACTIVE_USERS: 'RECEIVE_ACTIVE_USERS',
  GET_ALL_USERS: "GET_ALL_USERS"
};

export { socket, chatEvents, connectSocket };
