import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { AddCircle, Edit, SearchNormal1, Trash, UserAdd } from 'iconsax-react';
import { dispatch, useSelector } from 'store';
import {
  setusertypePageIndex,
  setusertypeTableSize,
  updateusertype,
  delusertype,
  setUserTypeSearch,
  resetStateusertype
} from 'store/reducers/usertype';
import MainCard from 'components/MainCard';
import useAuth from 'hooks/useAuth';
import Loader2 from 'components/Loader2';
import { deleteUserType, getAllUserType } from 'services/userType.service';
import IconButton from 'components/@extended/IconButton';
import Snackbar from 'utils/Snackbar';
import { OutlinedInput } from '@mui/material';
import CustomTable from 'components/@extended/CustomTable';
import { FormattedMessage } from 'react-intl';
import useConfig from 'hooks/useConfig';
import SkeletonTable from 'components/SkeletonTable';

const UserTypeList = (organizationId: any) => {
  const { user }: any = useAuth();
  const navigate = useNavigate();
  const { i18n } = useConfig();
  const pageIndex = useSelector((state: any) => state?.usertype)?.pageIndex;
  const search = useSelector((state: any) => state?.usertype)?.search;
  const size = useSelector((state: any) => state?.usertype)?.size;
  const usertype = useSelector((state: any) => state?.usertype)?.usertype;
  const [value, setValue] = useState<String | null>('');
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [isLoading, setLoading] = useState(usertype && usertype.length > 0 ? false : true);
  const [notFound, setNotFound] = useState(true);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    fetchusertype();
  }, [size, pageIndex, search]);

  useEffect(() => {
    return () => {
      dispatch(resetStateusertype());
    };
  }, [dispatch]);

  const handleSearch = () => {
    dispatch(setUserTypeSearch(value));
  };

  const fetchusertype = () => {
    try {
      setLoading(true);
      let reqData = {
        orgId: user?.organizationId?._id,
        skip: pageIndex,
        limit: size,
        search: search
      };
      getAllUserType(reqData).then((result) => {
        setLoading(false);
        if (result?.status === 200) {
          dispatch(updateusertype(result?.data?.data?.userTypeList));
          setCount(result?.data?.data?.count);
          if (result?.data?.data?.userTypeList?.length > 0) setNotFound(false);
        }
      });
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 403) {
        const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
        Snackbar(errorMessage, 'warning');
      } else {
        const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
        Snackbar(errorMessage, 'error');
      }
    }
  };
  const removeUserType = (deleteId: string) => {
    setDisabled(true);
    deleteUserType({ userTypeId: deleteId })
      .then((response) => {
        handleClose1();
        if (response?.status === 200) {
          dispatch(delusertype(deleteId));
          setCount(count - 1);
          Snackbar('User Type Removed Successfully', 'success');
        } else {
          Snackbar(response.data.message, 'success');
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
        setDisabled(false);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Options',
        accessor: '',
        Cell: (row: any) => (
          <>
            <Stack direction="row" spacing={0}>
              <Tooltip title={<FormattedMessage id="Edit" />}>
                <span>
                  <IconButton
                    color={'primary'}
                    onClick={() => {
                      if (user?.userTypeId?.permission?.UserTypes?.Update) {
                        navigate('/permissions/edit', {
                          state: {
                            userTypeId: row.cell.row.original._id,
                            userData: row.cell.row.original,
                            defaultPermission: row.cell.row.original.defaultPermissions
                          }
                        });
                      } else {
                        Snackbar("You don't have permission to edit user permissions", 'warning');
                      }
                    }}
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="Delete" />}>
                <span>
                  <IconButton
                    color={'error'}
                    onClick={() => {
                      if (user?.userTypeId?.permission?.UserTypes?.Delete) {
                        handleOpen();
                        setDeleteId(row?.cell?.row?.original?._id);
                      } else {
                        Snackbar("You don't have permission to delete user permissions", 'warning');
                      }
                    }}
                    disabled={row.cell.row.original.defaultPermissions}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </>
        )
      }
    ],
    [usertype]
  );
  const handleClose1 = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setusertypePageIndex(1));
    dispatch(setusertypeTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setusertypePageIndex(page));
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose1} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Remove User Type</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Remove the user type for this User?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                removeUserType(deleteId);
                handleClose1();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard
            title={
              <span style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">
                  <FormattedMessage id="User Type List" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="List of different user types." />
                </Typography>
              </span>
            }
            secondary={
              <div style={{ marginRight: '20px' }}>
                <Tooltip title={<FormattedMessage id="Add User Type" />}>
                  <span>
                    <IconButton
                      color="primary"
                      variant="dashed"
                      onClick={() => {
                        // navigate('/permissions/add', { state: { userData: { type: 'admin' } } });
                        if (user?.userTypeId?.permission?.UserTypes?.Create) {
                          navigate('/permissions/add');
                        } else {
                          Snackbar("You don't have permission to add userType", 'warning');
                        }
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            }
            content={false}
          >
            <>
              <Stack direction="row-reverse" spacing={1} sx={{ my: 1, mr: 1 }} alignItems="center">
                <Stack>
                  <Tooltip title={<FormattedMessage id="Search" />}>
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSearch}
                        sx={{ p: '9px', minWidth: 'auto' }}
                        disabled={!value || value === ''}
                      >
                        <SearchNormal1 />
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
                <Stack>
                  <OutlinedInput
                    value={value || search || ''}
                    onChange={(e) => {
                      setValue(e.target.value);
                      if (e.target.value === '') {
                        dispatch(setUserTypeSearch(''));
                        fetchusertype();
                      }
                    }}
                    placeholder={
                      i18n === 'fr'
                        ? `recherche à partir de ${count} enregistrements`
                        : i18n === 'ro'
                        ? `cauta din ${count} inregistrari`
                        : i18n === 'zh'
                        ? `从 ${count} 条记录中搜索`
                        : i18n === 'ar'
                        ? 'بحث في السجلات...'
                        : i18n === 'hindi'
                        ? `${count} रिकॉर्ड खोजें...`
                        : `Search ${count} records...`
                    }
                    id="start-adornment-email"
                  />
                </Stack>
              </Stack>
              {isLoading ? (
                <SkeletonTable columns={columns} />
              ) : (
                <>
                  {disabled && (
                    <div
                      style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        // background: 'rgba(10, 10, 10, 0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                        marginTop: '12px'
                      }}
                    >
                      <Loader2 />
                    </div>
                  )}
                  {usertype && (
                    <Box
                      sx={{
                        height: '100%',
                        overflow: 'hidden',
                        filter: disabled ? 'grayscale(100%) blur(3px)' : 'none'
                      }}
                    >
                      <CustomTable
                        icon={UserAdd}
                        text={<FormattedMessage id="No usertype to display" />}
                        link="/usertype/add"
                        sortArray={[]}
                        columns={columns}
                        data={usertype || []}
                        sort={[]}
                        setSortingData={null}
                        notFound={notFound}
                        search={search}
                        count={count}
                        size={size}
                        pageIndex={pageIndex}
                        handlePageChange={handlePageChange}
                        handleSizeChange={handleChange}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default UserTypeList;
