// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  error: null,
  user: {},
  roomId: '',
  showUnreadChip: true,
  chatId: null,
  programmaticScroll: false,
  userInteracted: false,
  chatLoading: false,
  skip: 1,
  isTyping: null,
  totalChatCount: 0,
  userSwitch: false,
  firstLoad: true,
  search: '',
  userChats: [],
  roomUsers: [],
  orgUsers: [],
  files: [],
  activeUser: {},
  chatMessage: null
};

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessage(state, action) {
      state.chatMessage = action.payload;
    },
    setActiveUser(state, action) {
      state.activeUser = action.payload;
    },
    resetTyping(state, action) {
      state.isTyping = {
        ...state.isTyping,
        [action.payload]: false
      };
    },
    setIsTyping(state, action) {
      const { senderId, isTyping } = action.payload;
      state.isTyping = {
        ...state.isTyping,
        [senderId]: isTyping
      };
    },
    setCurrentUser(state, action) {
      state.user = action.payload;
    },
    setUnreadChip(state, action) {
      state.showUnreadChip = action.payload;
    },
    setUserInteracted(state, action) {
      state.userInteracted = action.payload;
    },

    setFirstLoad(state, action) {
      state.firstLoad = action.payload;
    },

    setProgrammaticScroll(state, action) {
      state.programmaticScroll = action.payload;
    },

    setChatLoading(state, action) {
      state.chatLoading = action.payload;
    },

    setReadChats(state, action) {
      // let data = state.userChats.map((chat: any) => {
      //   if (chat._id === action.payload) {
      //     return { ...chat, isRead: true };
      //   }
      //   return chat;
      // });
      state.userChats = state.userChats;
    },

    setUserSwitching(state, action) {
      state.userSwitch = action.payload;
    },

    setTotalChatCount(state, action) {
      state.totalChatCount = action.payload;
    },

    addChatCount(state) {
      state.totalChatCount = state.totalChatCount + 1;
    },

    setChatSkip(state, action) {
      state.skip = action.payload;
    },

    setUserRoomId(state, action) {
      state.roomId = action.payload;
    },

    setUserChatId(state, action) {
      state.chatId = action.payload;
    },

    setUserSearch(state, action) {
      state.search = action.payload;
    },

    getOrgUser(state, action) {
      state.orgUsers = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getRoomUser(state, action) {
      state.roomUsers = action.payload;
    },

    setReduceChatCount(state, action) {
      let data = state?.roomUsers?.map((roomUser: any) => {
        if (roomUser?._id === state.roomId) {
          return { ...roomUser, unreadChatCount: roomUser.unreadChatCount - 1 };
        }
        return roomUser;
      });

      state.roomUsers = data;
      const chatData = state.userChats.map((chat: any) => {
        if (action.payload.includes(chat._id)) {
          return { ...chat, isRead: true };
        }
        return chat;
      });
      state.userChats = chatData;
    },

    addChatDrawerCount(state, action) {
      let data = state?.roomUsers?.map((roomUser: any) => {
        if (roomUser?._id === action.payload) {
          return { ...roomUser, unreadChatCount: roomUser.unreadChatCount + 1 };
        }
        return roomUser;
      });
      state.roomUsers = data;
    },

    // GET USER CHATS
    getUserChats(state, action) {
      let chatsToSave = action.payload;
      chatsToSave.filter((chat: any) => chat.roomId !== state.roomId);
      state.userChats = chatsToSave;
    },
    increaseUserChats(state, action) {
      state.userChats = [...state.userChats, action.payload];
    },
    resetStateChat: (state) => {
      return initialState;
    }
  }
});

export const {
  setActiveUser,
  hasError,
  setCurrentUser,
  setChatSkip,
  setUserSwitching,
  setUserInteracted,
  addChatDrawerCount,
  addChatCount,
  setTotalChatCount,
  increaseUserChats,
  setFirstLoad,
  setChatLoading,
  setReadChats,
  setUserRoomId,
  setUserChatId,
  setUserSearch,
  setReduceChatCount,
  getRoomUser,
  setUnreadChip,
  setProgrammaticScroll,
  getUserChats,
  resetTyping,
  getOrgUser,
  resetStateChat,
  setChatMessage,
  setIsTyping
} = chat.actions;

export default chat.reducer;
